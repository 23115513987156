import React, { useEffect, useState } from 'react';

import OrderCard from '../components/OrderCard';
import { useOrders } from '../context/OrderContext';
import '../css/Orders.css';
import Select from 'react-select';
import { FaFilter } from 'react-icons/fa'; // Import an icon from react-icons
import { ClipLoader } from 'react-spinners';

const Orders = () => {
  const { orders, loading, error, fetchOrders } = useOrders();
  const [filterStatus, setFilterStatus] = useState('all');

  useEffect(() => {
    fetchOrders();
  }, []);

  // Function to handle filter change
  const handleFilterChange = (selectedOption) => {
    setFilterStatus(selectedOption.value);
  };
  const options = [
    { value: 'all', label: 'All' },
    { value: 'active', label: 'Active' },
    { value: 'cancelled', label: 'Cancelled' },
  ];
  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #4CBB17',
      color: '#4CBB17',
      borderRadius: '8px',
    }),
  };
  const customOption = (props) => {
    return (
      <div
        {...props.innerProps}
        className={props.isFocused ? 'option-focused bg-primary' : 'option'}
      >
        {props.children}
      </div>
    );
  };

  // Filter orders based on the selected status
  const filteredOrders = orders.filter(
    (order) => filterStatus === 'all' || order.status === filterStatus
  );

  if (error) {
    return <p>Error fetching orders: {error}</p>;
  }

  return (
    <div className="orders-page bg-white">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <h1 className="font-semibold text-black text-2xl md:text-5xl">
            My Orders
          </h1>
          <div className="filter-dropdown flex items-center">
            <Select
              options={options}
              onChange={handleFilterChange}
              styles={customStyles}
              placeholder={
                <span className="flex items-center">
                  <FaFilter className="mr-2 text-primary" /> Filter
                </span>
              } // Add the icon here
            />
          </div>
        </div>
        <div className="orders-list">
          {loading ? (
            <ClipLoader color="#4CBB17" loading={loading} size={50} />
          ) : filteredOrders.length === 0 ? (
            <p>No orders</p>
          ) : (
            filteredOrders.map((order) => (
              <OrderCard key={order.id} order={order} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Orders;
