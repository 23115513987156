import React from 'react';
import StarRating from '../components/StarRating';

const ReviewsProgressBar = ({ product }) => {
  return (
    <>
      <div className="flex items-center w-full gap-4 md:justify-end mb-2">
        <StarRating rating={5} />
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{
              width: `${(1 / product.review_count) * 100}%`,
            }} // Use actual values
          ></div>
        </div>
        <div className="review-count">1</div>
      </div>
      <div className="flex items-center w-full gap-4 md:justify-end mb-2">
        <StarRating rating={4} />
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{
              width: `${(1 / product.review_count) * 100}%`,
            }} // Use actual values
          ></div>
        </div>
        <div className="review-count">1</div>
      </div>
      <div className="flex items-center w-full gap-4 md:justify-end mb-2">
        <StarRating rating={3} />
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{
              width: `${(0 / product.review_count) * 100}%`,
            }} // Use actual values
          ></div>
        </div>
        <div className="review-count">0</div>
      </div>
      <div className="flex items-center w-full gap-4 md:justify-end mb-2">
        <StarRating rating={2} />
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{
              width: `${(0 / product.review_count) * 100}%`,
            }} // Use actual values
          ></div>
        </div>
        <div className="review-count">0</div>
      </div>
      <div className="flex items-center w-full gap-4 md:justify-end mb-2">
        <StarRating rating={1} />
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{
              width: `${(0 / product.review_count) * 100}%`,
            }} // Use actual values
          ></div>
        </div>
        <div className="review-count">0</div>
      </div>
    </>
  );
};

export default ReviewsProgressBar;
