import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useEffect } from 'react';

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState({});
  const [featuredProducts, setFeaturedProducts] = useState({});
  const [loadingProducts, setLoadingProducts] = useState(true); // Loading state for products
  const [loadingFeatured, setLoadingFeatured] = useState(true); // Loading state for featured products
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    // Fetch all products
    setLoadingProducts(true);
    fetch(`${apiBaseUrl}/products/api/fetch-products/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setProducts(
          data.reduce((acc, product) => {
            acc[product.id] = product;
            return acc;
          }, {})
        );
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      })
      .finally(() => {
        setLoadingProducts(false); // Set loading to false after fetching
      });

    // Fetch featured products
    setLoadingFeatured(true);
    fetch(`${apiBaseUrl}/products/api/fetch-products/?status=featured`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setFeaturedProducts(
          data.reduce((acc, product) => {
            acc[product.id] = product;
            return acc;
          }, {})
        );
      })
      .catch((error) => {
        console.error('Error fetching featured products:', error);
      })
      .finally(() => {
        setLoadingFeatured(false); // Set loading to false after fetching
      });
  }, [apiBaseUrl]);

  const getProductById = (id) => {
    return products[id];
  };

  const generateHash = (productId, variantId) => {
    return (
      ((productId + variantId) * (productId + variantId + 1)) / 2 + variantId
    );
  };

  const retrieveIdsFromHash = (hash) => {
    const w = Math.floor((Math.sqrt(8 * hash + 1) - 1) / 2);
    const t = (w * (w + 1)) / 2;
    const variantId = hash - t;
    const productId = w - variantId;
    return { productId, variantId };
  };

  return (
    <ProductContext.Provider
      value={{
        products,
        featuredProducts,
        loadingProducts, // Provide loading state for products
        loadingFeatured, // Provide loading state for featured products
        getProductById,
        generateHash,
        retrieveIdsFromHash,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export const useProducts = () => useContext(ProductContext);

ProductProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
