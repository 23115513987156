import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import '../css/ReviewCard.css';
import StarRating from './StarRating';
import reviewDp from '../img/review-dp.svg';

const ReviewCard = ({ review }) => {
  return (
    <div className="review-card">
      <div className="flex justify-between w-full items-center">
        <div className="flex items-center gap-4 mb-1 md:mb-3">
          <img className="max-w-[40px]" src={reviewDp} />
          <h4 className="text-xs md:text-base">{review.customer_name}</h4>
        </div>
        <div className="text-[#5C5C5E] text-xs md:text-base font-normal">
          2 weeks ago
        </div>
      </div>
      <div className="mb-2 md:mb-4">
        <StarRating rating={review.rating} />
      </div>
      <h3 className="text-xs md:text-base font-semibold mb-1 md:mb-2">
        {review.heading}
      </h3>
      <div className="image-preview">
        {review.images.map((image, index) => (
          <img
            key={index}
            src={`${process.env.REACT_APP_API_BASE_URL}${image.image}`}
            alt={`Uploaded preview ${index + 1}`}
            className="small-image"
          />
        ))}
      </div>
      <p className="text-xs md:text-base">
        {review.comment || 'No comment available'}
      </p>
      <div className="divider" />
    </div>
  );
};

ReviewCard.propTypes = {
  review: PropTypes.shape({
    customer_name: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    heading: PropTypes.string.isRequired,
    comment: PropTypes.string,
    created_time: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default ReviewCard;
