// src/components/WhyChooseUs.js
import React, { useState } from 'react';

import '../css/WhyChooseUs.css'; // Import CSS for styling
import ReasonCard from './ReasonsCard';
import qualityProducts from '../img/quality-products.svg';
import customerSatisfaction from '../img/customer_satisfaction.svg';
import affordablePrices from '../img/affordable_prices.svg';
import Slider from 'react-slick';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1024);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

const WhyChooseUs = () => {
  const isMobile = useIsMobile();
  const reasons = [
    {
      title: 'Quality Products',
      description:
        'We ensure that our products meet the highest quality standards.',
      image: [qualityProducts], // Placeholder image
    },
    {
      title: 'Customer Satisfaction',
      description:
        'Our customers are our priority. We strive to provide the best service.',
      image: [customerSatisfaction],
    },
    {
      title: 'Affordable Prices',
      description:
        'We offer competitive pricing without compromising on quality.',
      image: [affordablePrices],
    },
  ];

  const settings = {
    className: 'center',
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '20px 20px 20px 0',

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '20px 20px 20px 0',
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="why-choose-us bg-lightGreen p-4 py-14">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row justify-between lg:items-center mb-14">
          <div>
            <small className="text-primary text-base">
              Farm to Table, Redifined
            </small>
            <h2 className="text-3xl md:text-5xl text-black font-semibold mb-4 md:mb-0">
              Why Choose Us?
            </h2>
          </div>
          <div className="max-w-[600px] text-sm md:text-base text-black font-normal">
            Discover the unique Setia’s naroi experience - where quality,
            sustainability, and community come together. We’re more than just a
            source of fresh products; we’re your partner in healthy,
            eco-conscious living.
          </div>
        </div>
        <div className="reasons-container flex flex-col lg:flex-row justify-between gap-10 md:gap-6">
          {isMobile ? (
            <Slider {...settings}>
              {reasons.map((reason, index) => (
                <ReasonCard reason={reason} key={index} />
              ))}
            </Slider>
          ) : (
            <>
              {reasons.map((reason, index) => (
                <ReasonCard reason={reason} key={index} />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
