import PropTypes from 'prop-types'; // Import PropTypes for props validation
import React, { useState } from 'react';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa';

import '../css/Collapsible.css';

const Collapsible = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible py-2">
      <div className="collapsible-title" onClick={toggleCollapsible}>
        {isOpen ? (
          <FaChevronDown fill="#5C5C5E" />
        ) : (
          <FaChevronRight fill="#5C5C5E" />
        )}
        <h2 className="ml-2 text-black font-medium text-sm md:text-lg w-full">
          {title}
        </h2>
      </div>
      <div
        className={`collapsible-content text-xs md:text-base font-normal text-black ${isOpen ? 'open' : ''}`}
      >
        {children}
      </div>
    </div>
  );
};

// Add PropTypes for prop validation
Collapsible.propTypes = {
  title: PropTypes.string.isRequired, // Ensures title is a required string
  children: PropTypes.node.isRequired, // Ensures children are provided
};

export default Collapsible;
