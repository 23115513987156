import React, { useState } from 'react';

import ProductCard from '../components/ProductCard';
import { useProducts } from '../context/ProductContext';
import ClipLoader from 'react-spinners/ClipLoader'; // Import ClipLoader
import Select from 'react-select';
import { FaFilter } from 'react-icons/fa'; // Import an icon from react-icons

function Products() {
  const { products, loadingProducts } = useProducts();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filterText, setFilterText] = useState(''); // New state for filter text

  const categories = [
    { value: null, label: 'All' },
    { value: 'Cooking oil', label: 'Cooking oil' },
    { value: 'Wheat', label: 'Wheat' },
    { value: 'Vegetables', label: 'Vegetables' },
    { value: 'Fruits', label: 'Fruits' },
    { value: 'Health', label: 'Health' },
    { value: 'Natural', label: 'Natural' },
  ];

  // Filter products based on the selected category and filter text
  const filteredProducts = Object.values(products).filter((product) => {
    const matchesCategory = selectedCategory
      ? product.category === selectedCategory
      : true;
    const matchesFilterText = product.name
      .toLowerCase()
      .includes(filterText.toLowerCase());
    return matchesCategory && matchesFilterText;
  });
  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #4CBB17',
      color: '#4CBB17',
      borderRadius: '8px',
    }),
  };

  return (
    <div className="products-page-container py-4 md:py-2 px-2 md:px-6 bg-white">
      <div className="container mx-auto md:mb-16">
        {/* Category Filter Section */}
        <div className="category-filter">
          <div className="flex items-center justify-between">
            <h2 className="font-semibold text-xl md:text-3xl text-black text-center my-4 md:my-10">
              Our Products
            </h2>
            <Select
              options={categories}
              onChange={(selectedOption) =>
                setSelectedCategory(selectedOption.value)
              }
              className="category-select"
              styles={customStyles}
              placeholder={
                <span className="flex items-center">
                  <FaFilter className="mr-2 text-primary" /> Filter
                </span>
              } // Add the icon here
            />
          </div>
        </div>
        <div>
          <div className="products-page-product-list flex flex-wrap gap-1 lg:gap-4">
            {loadingProducts ? ( // Use loadingProducts state
              <div className="flex justify-center items-center w-full">
                <ClipLoader
                  size={50}
                  color={'#4CBB17'}
                  loading={loadingProducts}
                />
              </div>
            ) : filteredProducts.length > 0 ? (
              filteredProducts.map((product) => (
                <ProductCard key={product.id} productId={product.id} />
              ))
            ) : (
              <p className="text-center text-gray-500">No products found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
