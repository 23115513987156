import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import { useCustomer } from '../context/CustomerContext';
import '../css/Signup.css';

const Signup = () => {
  const [step, setStep] = useState(1);
  const { login } = useAuth();
  const [inputMobileNumber, setInputMobileNumber] = useState('');
  const [otp, setOtp] = useState('');

  const { requestOtp, verifyOtp, errorMessage } = useCustomer();
  const navigate = useNavigate();

  const handleSubmitMobileNumber = async (e) => {
    e.preventDefault();
    if (await requestOtp(inputMobileNumber)) {
      setStep(2);
    }
  };

  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    if (await verifyOtp(inputMobileNumber, otp)) {
      login();
      navigate('/');
    }
  };

  return (
    <div className="signup-container mx-2 lg:mx-0">
      {step === 1 ? (
        <form onSubmit={handleSubmitMobileNumber} className="signup-form">
          <h2 className="font-bold text-xl md:text-3xl text-black">Sign Up</h2>
          <input
            type="tel"
            placeholder="Enter your mobile number"
            value={inputMobileNumber}
            onChange={(e) => setInputMobileNumber(e.target.value)}
            required
          />
          {errorMessage && (
            <p className="error-message" style={{ color: 'red' }}>
              {errorMessage}
            </p>
          )}
          <button type="submit" disabled={!inputMobileNumber}>
            Send OTP
          </button>
        </form>
      ) : (
        <form onSubmit={handleSubmitOtp} className="signup-form">
          <h2 className="font-bold text-xl md:text-3xl text-black">
            Verify OTP
          </h2>
          <input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
          {errorMessage && (
            <p className="error-message" style={{ color: 'red' }}>
              {errorMessage}
            </p>
          )}
          <button type="submit">Verify OTP</button>
        </form>
      )}
    </div>
  );
};

export default Signup;
