import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

const LatestPodcasts = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const podcasts = [
    {
      videoLink: 'https://www.youtube.com/watch?v=2BqcogsUiAA',
      title:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
      date: 'Jan 09, 2024',
    },
    {
      videoLink: 'https://www.youtube.com/watch?v=suATPK45sjk',
      title:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
      date: 'Jan 09, 2024',
    },
    {
      videoLink: 'https://www.youtube.com/watch?v=suATPK45sjk',
      title:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
      date: 'Jan 09, 2024',
    },
  ];

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '20px 20px 20px 0',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Show 1 slide below 768px
          slidesToScroll: 1,
          centerMode: true, // Adjust centerMode if needed
          centerPadding: '20px 20px 20px 0',
        },
      },
    ],
  };

  return (
    <div className="latest-podcasts bg-white py-10 md:py-20">
      <p className="text-primary text-base font-medium text-center">
        Interview video
      </p>
      <h3 className="font-semibold text-black text-2xl md:text-5xl text-center mb-10">
        Latest Podcast
      </h3>
      <div className="container mx-auto flex flex-col lg:flex-row gap-8">
        {isMobile ? (
          <Slider {...settings}>
            {podcasts.map((podcast, index) => {
              const videoId = new URL(podcast.videoLink).searchParams.get('v');
              const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;

              return (
                <div key={index} className="podcast-item px-4">
                  <a
                    href={podcast.videoLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="relative block"
                  >
                    <img
                      className="rounded-2xl mb-4"
                      src={thumbnailUrl}
                      alt={`Thumbnail for ${podcast.title}`}
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="bg-[#F19E30] rounded-full p-3">
                        <svg
                          className="w-6 h-6 md:w-12 md:h-12 text-white"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8 5v14l11-7z" />
                        </svg>
                      </div>
                    </div>
                  </a>
                  <h4 className="text-black font-xl font-medium mb-2">
                    {podcast.title}
                  </h4>
                  <p className="font-normal text-sm text-black opacity-50">
                    {podcast.date}
                  </p>
                </div>
              );
            })}
          </Slider>
        ) : (
          <>
            {podcasts.map((podcast, index) => {
              const videoId = new URL(podcast.videoLink).searchParams.get('v');
              const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;

              return (
                <div key={index} className="podcast-item px-4 md:px-0">
                  <a
                    href={podcast.videoLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="relative block"
                  >
                    <img
                      className="rounded-2xl mb-4"
                      src={thumbnailUrl}
                      alt={`Thumbnail for ${podcast.title}`}
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="bg-[#F19E30] rounded-full p-3">
                        <svg
                          className="w-6 h-6 md:w-12 md:h-12 text-white"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8 5v14l11-7z" />
                        </svg>
                      </div>
                    </div>
                  </a>
                  <h4 className="text-black font-xl font-medium mb-2">
                    {podcast.title}
                  </h4>
                  <p className="font-normal text-sm text-black opacity-50">
                    {podcast.date}
                  </p>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default LatestPodcasts;
