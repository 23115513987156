import React from 'react';

import Banner from './Banner';
import '../css/Testimonial.css';
import TestimonialSlider from './TestimonialSlider';

const Testimonials = () => {
  return (
    <section className="testimonials-section bg-lightGreen p-4 py-10 lg:py-20 pt-2 md:pt-10 lg:pt-20">
      <div className="container mx-auto p-2">
        <div className="flex flex-col lg:flex-row justify-between lg:items-center mb-6 md:mb-14">
          <div>
            <small className="text-primary text-base">Testimonials</small>
            <h2 className="text-2xl md:text-5xl text-black font-semibold mb-3 md:mb-0">
              Customers Say
            </h2>
          </div>
          <div className="max-w-[600px] text-sm md:text-base text-black font-normal">
            Our customers say they’ve never experienced such rich, high-quality
            wheat and farming products pure, fresh, and cultivated with care,
            ensuring the best yield and taste for every need.
          </div>
        </div>
        <TestimonialSlider />
        {/* <Banner mediaList={testimonials} height={'70vh'} /> */}
      </div>
    </section>
  );
};

export default Testimonials;
