import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import '../css/ProductDetails.css';
import AddToCartButton from '../components/AddToCartButton';
import Collapsible from '../components/Collapsible';
import ReviewCard from '../components/ReviewCard';
import StarRating from '../components/StarRating';
import { useCart } from '../context/CartContext';
import { useProducts } from '../context/ProductContext';
import { useReviews } from '../context/ReviewContext';
import QuantityHandling from '../components/QuantityHandling';
import ProductCard from '../components/ProductCard';
import ReviewsProgressBar from '../components/ReviewsProgressBar';
import ReviewStar from '../img/review-star.svg';
import ClipLoader from 'react-spinners/ClipLoader'; // Import ClipLoader

const ProductDetails = () => {
  const { id } = useParams();
  const { featuredProducts } = useProducts();
  const { getProductById, generateHash } = useProducts();
  const product = getProductById(Number(id));
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { setShowCart, addToCart } = useCart();
  const { isInCart } = useCart();
  const { reviews, fetchReviews, submitReview, error } = useReviews();
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [reviewText, setReviewText] = useState('');
  const [reviewRating, setReviewRating] = useState(5); // Default rating

  useEffect(() => {
    const loadProduct = async () => {
      try {
        // Simulate fetching product data
        const fetchedProduct = await getProductById(Number(id));
        if (fetchedProduct) {
          setSelectedVariant(fetchedProduct.variants[0]);
        }
      } catch (error) {
        console.error('Failed to load product:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    loadProduct();
  }, [id]);

  useEffect(() => {
    if (product && product.variants && product.variants.length > 0) {
      setSelectedVariant(product.variants[0]); // Set the default variant
    }
  }, [product]);

  useEffect(() => {
    const loadReviews = async () => {
      try {
        await fetchReviews(product.id);
      } catch (error) {
        console.error('Failed to load reviews:', error);
      }
    };

    if (product) {
      loadReviews();
    }
  }, [product]);

  if (loading) {
    return <ClipLoader size={50} color={'#F19E30'} loading={loading} />;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleBuyNowClick = () => {
    addToCart(generateHash(product.id, selectedVariant.id));
    setShowCart(true);
  };

  const handleVariantChange = (e) => {
    const variantId = Number(e.target.value);
    const selected = product.variants.find(
      (variant) => variant.id === variantId
    );
    setSelectedVariant(selected);
  };

  const handleReviewSubmit = async () => {
    const reviewData = {
      rating: reviewRating,
      comment: reviewText,
    };

    try {
      await submitReview(id, reviewData); // Use submitReview from context
      setReviewText(''); // Clear the input after submission
      setReviewRating(5); // Reset rating to default
    } catch (error) {
      console.log('Failed to submit review: ' + error.message);
    }
  };

  return (
    <>
      <div className="product-details-container container mx-auto">
        <div className="product-details-image-container lg:w-1/3 lg:sticky lg:top-20 lg:h-fit p-4">
          <div className="thumbnail-container hidden lg:flex">
            {selectedVariant?.images.map((imgSrc, index) => (
              <img
                key={index}
                src={`${process.env.REACT_APP_API_BASE_URL}${imgSrc.image}`}
                alt={`Thumbnail ${index + 1}`}
                className={`thumbnail ${currentImageIndex === index ? 'active' : ''}`}
                onClick={() => setCurrentImageIndex(index)}
              />
            ))}
          </div>
          <div className="product-details-carousel-container">
            <img
              src={`${process.env.REACT_APP_API_BASE_URL}${selectedVariant?.images[currentImageIndex].image}`}
              alt={`Product image ${currentImageIndex + 1}`}
              className="product-details-image"
            />
            <div className="thumbnail-container flex lg:hidden">
              {selectedVariant?.images.map((imgSrc, index) => (
                <img
                  key={index}
                  src={`${process.env.REACT_APP_API_BASE_URL}${imgSrc.image}`}
                  alt={`Thumbnail ${index + 1}`}
                  className={`thumbnail ${currentImageIndex === index ? 'active' : ''}`}
                  onClick={() => setCurrentImageIndex(index)}
                />
              ))}
            </div>
            <div className="product-details-buttons bg-white flex flex-row lg:flex-col fixed lg:relative bottom-0 left-0 right-0 lg:bg-transparent p-4 z-10 lg:p-0 w-full">
              {isInCart(generateHash(product.id, selectedVariant?.id)) && (
                <div className="flex lg:hidden w-full quantity-fixed">
                  <QuantityHandling
                    hash={generateHash(product.id, selectedVariant?.id)}
                  />
                </div>
              )}
              {selectedVariant ? (
                <>
                  {!isInCart(generateHash(product.id, selectedVariant?.id)) && (
                    <button
                      className="buy-now-button bg-secondary text-white text-sm md:text-base font-medium w-full py-2 md:py-4 rounded-lg"
                      onClick={handleBuyNowClick}
                    >
                      Buy Now
                    </button>
                  )}
                  <AddToCartButton
                    hash={generateHash(product.id, selectedVariant.id)}
                  />
                </>
              ) : (
                <p>Select a variant to add to cart.</p>
              )}
            </div>
          </div>
        </div>
        <div className="product-details-text">
          <h1 className="product-name font-bold text-xl md:text-3xl text-black md:mb-4">
            {product.name}
          </h1>
          {/* Product Rating */}
          <div className="product-reviews md:mb-4">
            <StarRating rating={product.average_rating} />
            {/* <p className="reviews-count">({product.review_count} reviews)</p> */}
          </div>
          <div className="variant-tabs flex gap-2 mb-6">
            {product.variants.map((variant) => (
              <span
                key={variant.id}
                className={`font-medium text-xs md:text-sm variant-tab bg-[rgba(241,158,48,0.7)] text-white p-1 px-4 md:px-6 rounded-xl  ${selectedVariant?.id === variant.id ? 'active' : ''}`}
                onClick={() => setSelectedVariant(variant)}
              >
                {parseInt(variant.weight)}kg
              </span>
            ))}
          </div>
          {isInCart(generateHash(product.id, selectedVariant?.id)) && (
            <div className="hidden lg:flex">
              <QuantityHandling
                hash={generateHash(product.id, selectedVariant?.id)}
              />
            </div>
          )}
          <h2 className="product-price font-semibold text-black text-xl md:text-3xl mb-2 md:mb-4">
            ${parseInt(selectedVariant?.price)}
          </h2>
          <p className="text-[#5C5C5E] mb-4 md:mb-6">
            M.R.P:{' '}
            <span className="test line-through">
              ${parseInt(selectedVariant?.mrp)}
            </span>
          </p>
          <p className="product-description text-xs md:text-base mb-6">
            {product.description}
          </p>

          {/* Collapsible Sections */}
          <Collapsible title={'Benefits'}>
            <p>Lorem ipsum odor amet, consectetuer adipiscing elit.</p>
          </Collapsible>
          <Collapsible title={'Description'}>
            <p>Lorem ipsum odor amet, consectetuer adipiscing elit.</p>
          </Collapsible>
          <Collapsible title={'Ingredients'}>
            <p>Lorem ipsum odor amet, consectetuer adipiscing elit.</p>
          </Collapsible>
          <Collapsible title={'Growing Process'}>
            <p>Lorem ipsum odor amet, consectetuer adipiscing elit.</p>
          </Collapsible>
          <Collapsible title={'Return Policy'}>
            <p>Lorem ipsum odor amet, consectetuer adipiscing elit.</p>
          </Collapsible>
          <div className="py-6 border-b-[1px] border-[#ccc]">
            {product.review_count > 0 && (
              <div className="reviews-holder flex flex-col md:flex-row md:items-center">
                <div className="customer-reviews py-4 md:py-10 md:w-1/2">
                  <h3 className="font-semibold text-xl md:text-3xl text-black mb-2 md:mb-6">
                    Customer Reviews
                  </h3>
                  <div>
                    <StarRating rating={product.average_rating} />
                  </div>
                  <div className="average-rating text-black font-normal text-2xl mb-4">
                    <span className="font-semibold text-black text-4xl pe-2">
                      {product.average_rating}
                    </span>
                    /5
                  </div>
                  <div className="review-count text-sm md:text-base text-black font-normal">
                    Based on {product.review_count} reviews
                  </div>
                </div>
                <div className="md:w-1/2">
                  <ReviewsProgressBar product={product} />
                </div>
              </div>
            )}
            <div className="add-review py-2 md:py-0">
              <div className="flex gap-6 items-center mb-6">
                <img
                  className="max-w-[40px] md:max-w-[80px]"
                  src={ReviewStar}
                />
                <div>
                  <p className="text-sm md:text-base font-normal text-black mb-2 md:mb-4">
                    Review this product
                  </p>
                  <p className="text-sm md:text-base font-normal text-black">
                    Share your thoughts with other customers
                  </p>
                </div>
              </div>
              {/* <textarea
                className="w-full p-2 border rounded mb-4"
                placeholder="Write your review here..."
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
              />
              <input
                type="number"
                className="w-full p-2 border rounded mb-4"
                placeholder="Rating (1-5)"
                value={reviewRating}
                onChange={(e) => setReviewRating(Number(e.target.value))}
                min="1"
                max="5"
              /> */}
              <button
                className="w-full py-2 md:py-4 bg-secondary text-white rounded-lg text-xs md:text-base"
                // onClick={handleReviewSubmit}
              >
                Write a review
              </button>
            </div>
          </div>

          {/* Reviews Section */}
          <div className="review-cards">
            {error ? (
              <p>{error}</p>
            ) : reviews.length > 0 ? (
              reviews.map((review) => (
                <ReviewCard key={review.id} review={review} />
              ))
            ) : (
              <p>No reviews yet.</p>
            )}
          </div>
        </div>
      </div>
      <div className="products bg-white py-10 md:py-20">
        <h1 className="featured-products text-black font-semibold text-3xl md:text-5xl m-0 mb-6 md:mb-14">
          You may also like
        </h1>
        <div className="container mx-auto">
          <div className="flex flex-wrap gap-2 lg:gap-4 px-1 md:px-5">
            {Object.values(featuredProducts).map((product) => {
              return <ProductCard key={product.id} productId={product.id} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
