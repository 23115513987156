import React, { useState } from 'react';
import '../css/ContactUs.css';
import { FaBuilding } from 'react-icons/fa'; // If you want to use it, you can add it in JSX

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact_number: '',
    query: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    setIsSubmitting(true);
    try {
      const response = await fetch(`${API_BASE_URL}/products/api/contact-us/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setResponseMessage('Your query has been submitted successfully!');
        setFormData({ name: '', email: '', contact_number: '', query: '' });
      } else {
        setResponseMessage('There was an error submitting your query.');
      }
    } catch (error) {
      setResponseMessage('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-lightGreen">
      <div className="contactUs-container py-10 md:py-20">
        <p className="text-primary text-base font-medium">Contact Us</p>
        <h3 className="font-semibold text-3xl md:text-5xl text-black mb-4">
          Get in touch
        </h3>
        <p className="font-normal text-black text-sm md:text-base mb-10">
          We’d love to hear from you. Please fill out this form.
        </p>
        <form onSubmit={handleSubmit} className="form">
          <div className="flex flex-col lg:flex-row gap-6 mb-2">
            <div className="input-group lg:w-1/2 flex flex-col">
              <label className="text-sm md:text-base text-left mb-2">
                Full Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="input"
                placeholder="Enter your full name"
              />
            </div>
            <div className="input-group lg:w-1/2 flex flex-col mb-2">
              <label className="text-sm md:text-base text-left mb-2">
                Phone Number
              </label>
              <input
                type="tel"
                id="contact_number"
                name="contact_number"
                value={formData.contact_number}
                onChange={handleChange}
                required
                className="input"
                placeholder="Enter your phone number"
              />
            </div>
          </div>

          <div className="input-group lg:w-1/2 flex flex-col mb-2">
            <label className="text-sm md:text-base text-left mb-2">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="input"
              placeholder="Enter your email address"
            />
          </div>

          <div className="input-group w-full flex flex-col">
            <label className="text-sm md:text-base text-left mb-2">
              Message
            </label>
            <textarea
              id="query"
              name="query"
              value={formData.query}
              onChange={handleChange}
              required
              className="textarea"
              placeholder="Type your message"
            ></textarea>
          </div>
          <div className="input-group w-full flex flex-col mb-4">
            <label className="flex items-center text-sm md:text-base">
              <input type="checkbox" className="mr-2" required />
              You agree to our friendly privacy policy.
            </label>
          </div>

          <button
            type="submit"
            className="query-button"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Send Message'}
          </button>
        </form>
        {responseMessage && <p>{responseMessage}</p>}
      </div>
    </div>
  );
};

export default ContactUs;
