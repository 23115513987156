// src/components/WhyChooseUs.js
import React from 'react';
import '../css/MyCard.css';

const ReasonsCard = ({ reason }) => {
  return (
    <div className="reason-card bg-white rounded-md p-4 py-8 md:py-16 mx-2 lg:mx-0 relative lg:box-shadow">
      <img
        src={reason.image}
        alt={`Image for ${reason.title}`}
        className="reason-image bg-primary max-w-[30px] md:max-w-[50px] p-1 md:p-3 rounded-full lg:absolute mb-2 lg:mb-0 top-[-16px]"
      />
      <div className="title-box text-black font-semibold text-base md:text-2xl mb-2 md:mb-4">
        <h3>{reason.title}</h3>
      </div>
      <p className="text-xs md:text-base max-w-[300px] lg:max-w-full text-black font-normal">
        {reason.description}
      </p>
    </div>
  );
};

export default ReasonsCard;
