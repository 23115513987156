import PropTypes from 'prop-types';
import React, { useState } from 'react';
import '../css/Banner.css';
import BannerSlider from './BannerSlider';

const Banner = () => {
  return (
    <div className="banner">
      <BannerSlider />
    </div>
  );
};
export default Banner;
