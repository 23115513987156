import React from 'react';
import '../css/Footer.css';
import Logo from '../img/logo.png';
import InstaLogo from '../img/insta.svg';
import FacebookLogo from '../img/facebook.svg';
import TwitterLogo from '../img/twitter.svg';
import LinkedIn from '../img/linkedin.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer bg-primary">
      <div className="container mx-auto flex flex-col lg:flex-row justify-center px-4 lg:px-10 pt-4 lg:pt-14 gap-6 lg:gap-10">
        <div className="footer-column flex items-center lg:items-start flex-row lg:flex-col flex-grow">
          <img className="max-w-[25px] md:max-w-[65px]" src={Logo} />
          <div className="text-base text-white flex ml-2 lg:ml-0">
            <span>Setia&#39;s</span>
            <span>&nbsp;Naroi&nbsp;</span>
            <span>Sehat</span>
          </div>
        </div>
        <div className="flex footer-column">
          <div className="footer-column flex-grow">
            <h4 className="inline-block footer-heading text-white font-bold text-sm md:text-xl mb-4 md:mb-6 border-b-[2px] border-white">
              Quick Links
            </h4>
            <ul className="footer-links text-xs md:text-sm">
              <li className="mb-4 md:mb-6">
                <Link to="/about">About Us</Link>
              </li>
              <li className="mb-4 md:mb-6">
                <Link to="/products">Products</Link>
              </li>
              <li className="mb-4 md:mb-6">
                <Link to="/contact">Contact Us</Link>
              </li>
              <li className="mb-4 md:mb-6">
                <Link to="#">Terms & Conditions</Link>
              </li>
              <li className="mb-4 md:mb-6">
                <Link to="#">Support</Link>
              </li>
            </ul>
          </div>

          <div className="footer-column flex-grow">
            <h4 className="inline-block footer-heading text-white font-bold text-sm md:text-xl mb-4 md:mb-6 border-b-[2px] border-white">
              Information
            </h4>
            <p className="mb-6 text-white text-xs md:text-sm">
              {' '}
              123 Street, New York, USA{' '}
            </p>
            <p className="mb-6 text-white text-xs md:text-sm">
              {' '}
              +012 345 67890{' '}
            </p>
            <p className="mb-6 text-white text-xs md:text-sm">
              {' '}
              admin@naroisehat.in{' '}
            </p>
          </div>
        </div>

        <div className="footer-column flex-grow">
          <h4 className="inline-block footer-heading text-white font-bold text-sm md:text-xl mb-4 md:mb-6 border-b-[2px] border-white">
            Social Links
          </h4>
          <div className="flex items-center gap-6">
            <Link
              to="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="max-w-[25px] md:max-w-[65px]"
                src={FacebookLogo}
              />
            </Link>
            <Link
              to="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="max-w-[25px] md:max-w-[65px]" src={LinkedIn} />
            </Link>
            <Link
              to="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="max-w-[25px] md:max-w-[65px]" src={InstaLogo} />
            </Link>
            <Link
              to="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="max-w-[25px] md:max-w-[65px]" src={TwitterLogo} />
            </Link>
          </div>
        </div>
        <div className="footer-column flex-grow">
          <h4 className="inline-block footer-heading text-white font-bold text-sm md:text-xl mb-4 md:mb-6 border-b-[2px] border-white">
            Subscribe Our Newsletter
          </h4>
          <form className="flex flex-row items-center gap-2 max-w-[400px] lg:max-w-full">
            <input
              type="email"
              placeholder="Enter your email id"
              className="py-2 lg:py-3 px-2 rounded-md text-black w-2/3 text-sm"
              required
            />
            <button
              type="submit"
              className="bg-secondary text-white py-2 lg:py-3 px-6 lg:px-3 rounded-md text-sm w-1/3"
            >
              Subscribe
            </button>
          </form>
        </div>
      </div>

      <div className="footer-bottom">
        <p className="p-4 md:p-8 text-lightWhite">
          Copyright &copy; {new Date().getFullYear()} Naroi Sehat. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
