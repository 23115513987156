import React from 'react';
import AboutImage from '../img/about-sideimg.png';
import Tick from '../img/tick-mark.svg';

const WhatWeDo = () => {
  return (
    <div className="bg-lightGreen">
      <div className="container mx-auto p-2 flex flex-col-reverse lg:flex-row lg:items-center">
        <div className="py-6 lg:py-0 lg:w-7/12">
          <p className="text-primary text-base font-medium">What We Do</p>
          <h3 className="font-semibold text-2xl md:text-5xl text-black text-left mb-6 leading-tight">
            Nurturing Quality for a Decade
          </h3>
          <p className="text-sm md:text-base font-normal text-black max-w-2xl mb-8">
            At Setia’s Naroi Sehat, we are dedicated to connecting farmers with
            families, ensuring that every bite you take is filled with freshness
            and flavor.
          </p>
          <ul>
            <li className="flex items-center mb-4 text-xs md:text-base">
              <span className="mr-2 md:mr-0">
                <img src={Tick} className="lg:pr-4" />
              </span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </li>
            <li className="flex items-center mb-4 text-xs md:text-base">
              <span className="mr-2 md:mr-0">
                <img src={Tick} className="lg:pr-4" />
              </span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </li>
            <li className="flex items-center mb-4 text-xs md:text-base">
              <span className="mr-2 md:mr-0">
                <img src={Tick} className="lg:pr-4" />
              </span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </li>
            <li className="flex items-center mb-4 text-xs md:text-base">
              <span className="mr-2 md:mr-0">
                <img src={Tick} className="lg:pr-4" />
              </span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </li>
          </ul>
        </div>
        <img
          src={AboutImage}
          alt="About Image"
          className="about-image lg:w-5/12"
        />
      </div>
    </div>
  );
};

export default WhatWeDo;
