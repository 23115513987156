import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useProducts } from '../context/ProductContext';

import Collapsible from './Collapsible';

import '../css/OrderCard.css';
import greyTick from '../img/grey-tick.svg';

const OrderCard = ({ order }) => {
  const [product, setProduct] = useState(null);
  const [variant, setVariant] = useState(null);
  const { getProductById } = useProducts();

  useEffect(() => {
    const fetchProduct = async () => {
      const product = await getProductById(order.product_info.product.id);
      const variant = product
        ? product.variants.find(
            (variant) => variant.id === order.product_info.product.variant.id
          )
        : null;
      setProduct(product);
      setVariant(variant);
    };
    fetchProduct();
  }, [order]);
  const estimatedGST = 27;
  // Calculate total payable
  const subtotal = order.product_info.product.variant.price;
  const discount =
    subtotal * (order.product_info.product.variant.discount / 100);
  const deliveryCharge = Math.round(
    order.product_info.product.variant.delivery_charge
  );
  const totalPayable = subtotal - discount + estimatedGST + deliveryCharge;
  console.log(product);

  return (
    <div className="order-card">
      {product && variant && (
        <Collapsible
          title={
            <div className="w-full">
              <div className="order-card-title mb-4">
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}${variant.images[0].image}`}
                  alt="Product"
                  className="order-card-image"
                />
                <div className="order-card-text">
                  <span className="order-card-name font-semibold text-black text-base md:text-lg">
                    {product.name}
                  </span>
                  <p className="text-xs md:text-base text-[rgba(0,0,0,0.6)]">
                    <span>Purchased on - </span>{' '}
                    {new Date(order.created_time).toLocaleDateString('en-US', {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </p>
                  {/* <p className="order-card-description">{product.description}</p> */}
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between md:items-center">
                <p className="text-xs md:text-base font-semibold text-primary capitalize">
                  <span className="text-black">Status:</span> Order{' '}
                  {order.status}
                </p>
                {order.status === 'cancelled' && (
                  <div>
                    <button className="bg-transparent border-[1px] border-secondary text-secondary text-xs md:text-lg px-5 py-1 md:py-3 rounded-lg mx-2 my-2 md:my-0">
                      Write a review
                    </button>
                    <button className="bg-secondary border-[1px] border-secondary text-white text-xs md:text-lg px-5 py-1 md:py-3 rounded-lg mx-2">
                      Buy it again
                    </button>
                  </div>
                )}
              </div>
            </div>
          }
        >
          <div className="order-details py-4 px-2">
            {order.status === 'cancelled' && (
              <div className="order-progress py-4 pb-6">
                <div className="flex">
                  <img className="w-10 h-10" src={greyTick} />
                  <div className="ml-2">
                    <p className="font-semibold">Order Placed</p>
                    <p>
                      {new Date(order.created_time).toLocaleDateString(
                        'en-US',
                        {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                        }
                      )}
                    </p>
                  </div>
                </div>
                <div className="grey-line w-[1px] h-10 bg-[rgba(0,0,0,0.5)]"></div>
                <div className="flex">
                  <img className="w-10 h-10" src={greyTick} />
                  <div className="ml-2">
                    <p className="font-semibold">Order Cancelled</p>
                    <p>
                      {new Date(order.created_time).toLocaleDateString(
                        'en-US',
                        {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                        }
                      )}
                    </p>
                    <p className="w-full bg-[#DDDDDD] py-2 px-4 text-sm mt-2 rounded-lg">
                      Sorry your order was cancelled
                    </p>
                  </div>
                </div>
              </div>
            )}
            <p className="font-semibold text-black text-base md:text-lg mb-2 md:mb-4">
              Price Details
            </p>
            <div className="flex justify-between py-2">
              <div>Subtotal ({order.quantity} item)</div>
              <div>₹{subtotal}</div>
            </div>
            <div className="flex justify-between py-2">
              <div>
                Discount (
                {Math.round(order.product_info.product.variant.discount)} %)
              </div>
              <div>- ₹{discount.toFixed(2)}</div>
            </div>
            <div className="flex justify-between py-2">
              <div>Estimated GST</div>
              <div>₹{estimatedGST}</div>
            </div>
            <div className="flex justify-between py-2">
              <div>Delivery Charge</div>
              <div>₹{deliveryCharge}</div>
            </div>
            <div className="flex justify-between py-2 border-t-[1px] border-[#E5E9EB]">
              <div className="font-semibold text-base md:text-lg">
                Total Payable
              </div>
              <div className="font-semibold text-base md:text-lg">
                ₹{Math.round(totalPayable)}
              </div>
            </div>
          </div>
        </Collapsible>
      )}
    </div>
  );
};

export default OrderCard;
