import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FaTimes, FaPlus, FaMinus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import { useCart } from '../context/CartContext';
import { useOrders } from '../context/OrderContext';
import { useProducts } from '../context/ProductContext';

import QuantityHandling from './QuantityHandling';
import '../css/CartDrawer.css';

const CartDrawer = ({ show }) => {
  const { cartItems, removeFromCart, setShowCart, clearCart, getQuantity } =
    useCart();
  const [isPriceDetailsOpen, setIsPriceDetailsOpen] = useState(true);
  const { submitOrder } = useOrders();
  const { getProductById, retrieveIdsFromHash } = useProducts();
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const totalQuantity = cartItems.reduce(
    (total, hash) => total + getQuantity(hash),
    0
  );

  const { totalPrice, totalDiscount, deliveryCharges } = cartItems.reduce(
    (totals, hash) => {
      const ids = retrieveIdsFromHash(hash);
      const product = getProductById(ids.productId);
      const variant = product
        ? product.variants.find((variant) => variant.id === ids.variantId)
        : null;
      const quantity = getQuantity(hash);

      if (product && variant) {
        const itemPrice = variant.price * quantity;
        const itemDiscount = itemPrice * (variant.discount / 100);
        const deliveryCharge = parseInt(variant.delivery_charge, 10);

        totals.totalPrice += itemPrice;
        totals.totalDiscount += itemDiscount;
        totals.deliveryCharges += deliveryCharge;
      }

      return totals;
    },
    { totalPrice: 0, totalDiscount: 0, deliveryCharges: 0 }
  );

  const gst = 27; // Example fixed GST value
  const totalPayable = totalPrice - totalDiscount + gst + deliveryCharges;

  const handleOrderSubmission = async () => {
    if (!isLoggedIn) {
      navigate('/signup');
      setShowCart(false);
      return;
    }
    submitOrder();
    clearCart();
    setShowCart(false);
  };

  const isCartEmpty = cartItems.length === 0;

  return (
    <>
      {show && <div className="backdrop" onClick={() => setShowCart(false)} />}
      <div className={`cart-drawer ${show ? 'open' : ''}`}>
        <div className="cart-drawer-header">
          <h2>Cart</h2>
          <button onClick={() => setShowCart(false)} className="close-button">
            <FaTimes />
          </button>
        </div>
        <div
          className={`cart-holder ${isCartEmpty ? 'empty-cart-holder' : ''}`}
        >
          <div
            className={`cart-drawer-content ${isCartEmpty ? 'empty-cart' : ''}`}
          >
            {isCartEmpty ? (
              <div className="flex flex-col justify-between h-full">
                <p className="text-sm md:text-base">
                  Your cart is currently empty.
                </p>
                <div className="my-10 flex flex-col">
                  <button
                    className="bg-transparent border-[1px] border-primary text-primary text-lg px-5 py-2 md:py-4 rounded-lg mb-10 mx-10"
                    onClick={() => {
                      navigate('/products');
                      setShowCart(false);
                    }}
                  >
                    All Products
                  </button>
                  <button
                    className="bg-transparent border-[1px] border-primary text-primary text-lg px-5 py-2 md:py-4 rounded-lg mb-10 mx-10"
                    onClick={() => {
                      navigate('/products');
                      setShowCart(false);
                    }}
                  >
                    In Stock
                  </button>
                </div>
                <button
                  className="bg-secondary text-white text-lg px-5 py-4 rounded-lg"
                  onClick={() => setShowCart(false)}
                >
                  Continue Shopping
                </button>
              </div>
            ) : (
              <>
                {cartItems.map((hash) => {
                  const ids = retrieveIdsFromHash(hash);
                  const product = getProductById(ids.productId);
                  const variant = product
                    ? product.variants.find(
                        (variant) => variant.id === ids.variantId
                      )
                    : null;

                  if (!product || !variant) return null;

                  return (
                    <div key={hash} className="cart-item py-2 lg:py-0">
                      <img
                        src={`${process.env.REACT_APP_API_BASE_URL}${variant.images[0].image}`}
                        alt={product.name}
                        className="cart-item-image rounded-md mb-2"
                      />
                      <div className="cart-item-details">
                        <h3 className="cart-item-name mb-2 md:mb-4">
                          {product.name} - {variant.weight}kg
                        </h3>
                        <p className="cart-item-weight mb-4">
                          ₹{variant.price * getQuantity(hash)}
                          <span className="px-2 text-tiny text-[rgba(0,0,0,0.6)] font-normal mb-4 line-through">
                            ₹{variant.mrp}
                          </span>
                          <span className="px-2 md:px-4 text-tiny text-[rgba(0,0,0,0.6)] font-normal mb-4">
                            {variant.discount}% OFF
                          </span>
                        </p>
                        <QuantityHandling
                          hash={hash}
                          className="cart-drawer-quantity"
                        />
                      </div>
                    </div>
                  );
                })}

                <div className="coupons">
                  <h3 className="text-base md:text-lg font-semibold text-left text-black mb-4">
                    Coupons
                  </h3>
                  <input
                    type="string"
                    placeholder="Enter coupon code"
                    className="coupon-input"
                  />
                </div>
              </>
            )}
          </div>

          {!isCartEmpty && (
            <div className="price-details pt-6">
              <h3
                className="font-semibold text-base md:text-lg text-black mb-4 flex justify-between"
                onClick={() => setIsPriceDetailsOpen(!isPriceDetailsOpen)}
              >
                Price Details{' '}
                <span>{isPriceDetailsOpen ? <FaMinus /> : <FaPlus />}</span>
              </h3>
              {isPriceDetailsOpen && (
                <>
                  <div className="flex justify-between py-2">
                    <div className="text-sm md:text-base">
                      Subtotal ({totalQuantity})
                    </div>
                    <div className="text-sm md:text-base">₹{totalPrice}</div>
                  </div>
                  <div className="flex justify-between py-2">
                    <div className="text-sm md:text-base">Discount</div>
                    <div className="text-sm md:text-base">
                      ₹{totalDiscount.toFixed(2)}
                    </div>
                  </div>
                  <div className="flex justify-between py-2">
                    <div className="text-sm md:text-base">Estimated GST</div>
                    <div className="text-sm md:text-base">₹{gst}</div>
                  </div>
                  <div className="flex justify-between py-2">
                    <div className="text-sm md:text-base">Delivery Charge</div>
                    <div className="text-sm md:text-base">
                      ₹{deliveryCharges}
                    </div>
                  </div>
                  <div className="total-payable py-6 flex justify-between">
                    <div className="font-semibold text-black text-base md:text-lg">
                      TOTAL PAYABLE
                    </div>
                    <div className="text-base md:text-lg">
                      ₹{totalPayable.toFixed(2)}
                    </div>
                  </div>
                </>
              )}
              <div className="cart-drawer-footer bottom-0">
                <div className="cart-total">
                  <span className="text-sm md:text-base">
                    Total ₹{totalPayable}
                  </span>
                  <span className="block text-xs text-[rgba(0,0,0,0.5)]">
                    (incl. all taxes)
                  </span>
                </div>
                <button
                  className="proceed-to-buy-button"
                  onClick={handleOrderSubmission}
                >
                  Proceed to Buy
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

CartDrawer.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default CartDrawer;
