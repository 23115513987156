import React from 'react';
import Slider from 'react-slick';
import '../css/Testimonial.css';
import CommasStart from '../img/commas_start.png';
import CommasEnd from '../img/commas_end.png';

const TestimonialSlider = () => {
  const settings = {
    className: 'center',
    dots: true, // Show dots for navigation
    infinite: false, // Infinite loop sliding
    speed: 500, // Transition speed
    slidesToShow: 3, // Number of slides to show
    slidesToScroll: 1, // Number of slides to scroll
    swipe: true, // Enable swipe gestures
    touchMove: true, // Enable touch move
    responsive: [
      {
        breakpoint: 1024, // Mobile breakpoint
        settings: {
          slidesToShow: 2, // Show 1 slide on mobile
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          centerPadding: '20px 20px 20px 0',
        },
      },
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          slidesToShow: 2, // Show 1 slide on mobile
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          centerPadding: '20px 20px 20px 0',
        },
      },
      {
        breakpoint: 480, // Mobile breakpoint
        settings: {
          slidesToShow: 1, // Show 1 slide on mobile
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          centerPadding: '20px 20px 20px 0',
        },
      },
    ],
  };

  const testimonials = [
    {
      id: 1,
      name: 'Albert Flores',
      position: 'Product Manager at Jomanar',
      review:
        'People now recognize that having a good performance conversation means that something happens as a result.',
    },
    {
      id: 2,
      name: 'Albert Flores',
      position: 'Product Manager at Jomanar',
      review:
        'People now recognize that having a good performance conversation means that something happens as a result.',
    },
    {
      id: 3,
      name: 'Albert Flores',
      position: 'Product Manager at Jomanar',
      review:
        'People now recognize that having a good performance conversation means that something happens as a result.',
    },
    {
      id: 4,
      name: 'Albert Flores',
      position: 'Product Manager at Jomanar',
      review:
        'People now recognize that having a good performance conversation means that something happens as a result.',
    },
    {
      id: 5,
      name: 'Albert Flores',
      position: 'Product Manager at Jomanar',
      review:
        'People now recognize that having a good performance conversation means that something happens as a result.',
    },
  ];

  return (
    <div className="testimonials-slider">
      <Slider {...settings}>
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="testimonial-slide">
            <div className="testimonial-slide-holder text-black bg-white p-4 lg:p-8 py-4 md:py-14 m-2 rounded-xl">
              <h3 className="font-bold text-md md:text-xl mb-2">
                {testimonial.name}
              </h3>
              <h4 className="text-xs lg:text-sm font-normal">
                {testimonial.position}
              </h4>
              <p className="text-xs lg:text-base font-medium relative my-8 lg:my-14">
                <img
                  className="banner-media-element max-w-[10px] lg:max-w-[20px] absolute top-[-10px] lg:top-[-25px] h-auto left-[-10px]"
                  src={CommasStart}
                />
                {testimonial.review}
                <img
                  className="banner-media-element max-w-[10px] lg:max-w-[20px] absolute bottom-[-10px] lg:bottom-[-20px] h-auto right-[-5px] lg:right-[-10px]"
                  src={CommasEnd}
                />
              </p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialSlider;
