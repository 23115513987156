import PropTypes from 'prop-types';
import React from 'react';
import { FaPlus, FaMinus, FaTrash } from 'react-icons/fa';

import { useCart } from '../context/CartContext'; // Import CartContext

const QuantityHandling = ({ hash }) => {
  const { removeFromCart, addToCart, getQuantity } = useCart(); // Access cart items from CartContext

  const quantity = getQuantity(hash);

  const handleInputChange = (e) => {
    const value = Number(e.target.value);
    if (value >= 1) {
      if (value - quantity > 0) {
        addToCart(hash, value - quantity);
      } else {
        removeFromCart(hash, quantity - value);
      }
    }
  };

  if (quantity === 0) {
    return null; // Hide the component when quantity is zero
  }

  return (
    <div className="quantity flex justify-between max-w-[80px] md:max-w-[120px] border-[1px] border-secondary rounded-lg px-2 lg:px-4 py-2 lg:py-2 lg:mb-4">
      {quantity === 1 ? (
        <button
          className="quantity-modifier text-[8px] md:text-tiny"
          onClick={() => removeFromCart(hash)}
        >
          <FaTrash className="text-red" />
        </button>
      ) : (
        <button
          className="quantity-modifier text-[8px] md:text-tiny"
          onClick={() => removeFromCart(hash)}
        >
          <FaMinus className="text-secondary" />
        </button>
      )}
      <input
        type="number"
        value={quantity}
        onChange={handleInputChange}
        min="1"
        className="number no-arrows text-[12px] md:text-tiny text-center w-auto max-w-[30px] md:max-w-[65px] flex items-center justify-center bg-transparent"
      />
      <button className="quantity-modifier" onClick={() => addToCart(hash)}>
        <FaPlus className="text-secondary text-[8px] md:text-tiny" />
      </button>
    </div>
  );
};

QuantityHandling.propTypes = {
  hash: PropTypes.number.isRequired,
};

export default QuantityHandling;
