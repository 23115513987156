import React, { useState } from 'react';

import Banner from '../components/Banner';
import ProductCard from '../components/ProductCard';
import ClipLoader from 'react-spinners/ClipLoader'; // Import ClipLoader
import '../css/Home.css';
import Testimonials from '../components/Testimonials';
import WhyChooseUs from '../components/WhyChooseUs';
import { useProducts } from '../context/ProductContext';
import video from '../img/Agrowfarm.mp4';
import BannerImage from '../img/banner_bg.png';
import image1 from '../img/image1.jpeg';
import Arrow from '../img/arrow.svg';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

function Home() {
  const { featuredProducts, loadingFeatured } = useProducts();
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate('/products'); // Redirect to the products page
  };
  const productArray = Object.values(featuredProducts);

  return (
    <div className="home-page-container">
      <Banner />
      <WhyChooseUs />
      <div className="products bg-white py-10 md:py-20">
        <p className="text-center text-primary font-medium">Our Products</p>
        <h1 className="featured-products text-black font-semibold text-2xl md:text-5xl m-0 mb-6 md:mb-14">
          Explore our fresh produces
        </h1>
        <div className="container mx-auto">
          {loadingFeatured ? ( // Check if loading
            <div className="flex justify-center">
              <ClipLoader
                size={50}
                color={'#4CBB17'}
                loading={loadingFeatured}
              />
            </div>
          ) : (
            <div>
              <div className="block lg:hidden">
                {productArray.length > 1 ? (
                  <Slider
                    {...{
                      dots: true,
                      arrows: false,
                      infinite: false,
                      speed: 500,
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      rows: 1,
                    }}
                  >
                    {productArray.map((product) => (
                      <div key={product.id}>
                        <ProductCard productId={product.id} />
                      </div>
                    ))}
                  </Slider>
                ) : (
                  // Render the single product without the slider
                  productArray.map((product) => (
                    <div key={product.id} className="flex justify-start">
                      <ProductCard productId={product.id} />
                    </div>
                  ))
                )}
              </div>
              {/* Regular grid for desktop view */}
              <div className="products-page-product-list hidden lg:flex flex-wrap gap-2 lg:gap-4 px-2 md:px-5">
                {Object.values(featuredProducts).map((product) => (
                  <ProductCard key={product.id} productId={product.id} />
                ))}
              </div>
            </div>
          )}
          <div className="mt-10 lg:mt-2 flex justify-center">
            <button
              className="bg-secondary text-tiny md:text-base text-white py-2 md:py-4 px-4 md:px-10 rounded-xl my-4 md:my-6 flex"
              onClick={handleRedirect}
            >
              View all products
              <span>
                <img src={Arrow} />
              </span>
            </button>
          </div>
        </div>
      </div>
      <Testimonials />
    </div>
  );
}

export default Home;
