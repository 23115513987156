import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import BannerImage from '../img/banner_bg.png';
import BannerImageMobile from '../img/banner_bg_mbl.png'; // Add mobile image
import { useNavigate } from 'react-router-dom';

const BannerSlider = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = {
    dots: true, // Show dots for navigation
    infinite: true, // Infinite loop sliding
    speed: 500, // Transition speed
    slidesToShow: 1, // Number of slides to show
    slidesToScroll: 1, // Number of slides to scroll
    arrows: false,
  };

  const bannerSlides = [
    {
      id: 1,
      img: isMobile ? BannerImageMobile : BannerImage, // Use mobile image if isMobile is true
      heading: 'From our farm to your table – Quality you can trust',
      text: 'Bringing you the best in wheat and farming products, grown with care and delivered with a commitment to quality.',
    },
    {
      id: 2,
      img: isMobile ? BannerImageMobile : BannerImage, // Use mobile image if isMobile is true
      heading: 'From our farm to your table – Quality you can trust',
      text: 'Bringing you the best in wheat and farming products, grown with care and delivered with a commitment to quality.',
    },
    {
      id: 3,
      img: isMobile ? BannerImageMobile : BannerImage, // Use mobile image if isMobile is true
      heading: 'From our farm to your table – Quality you can trust',
      text: 'Bringing you the best in wheat and farming products, grown with care and delivered with a commitment to quality.',
    },
  ];

  return (
    <div className="banner-slider">
      <Slider {...settings}>
        {bannerSlides.map((slide) => (
          <div key={slide.id} className="banner-slide">
            <div className="banner-slide-holder relative">
              <div className="container mx-auto px-3">
                <div className="banner-content-holder absolute top-0 h-full bottom-0 flex flex-col justify-center items-center">
                  <div className="banner-content max-w-[700px]">
                    <h2 className="text-white font-bold text-2xl lg:text-6xl mb-2 md:mb-4 leading-tight max-w-[280px] md:max-w-[350px] lg:max-w-full">
                      {slide.heading}
                    </h2>
                    <p className="text-white text-xs md:text-base lg:text-2xl font-normal mb-2 md:mb-6 max-w-[300px] lg:max-w-full">
                      {slide.text}
                    </p>
                    <button
                      className="bg-secondary text-white text-xs md:text-base px-3 md:px-5 py-2 md:py-3 rounded-lg"
                      onClick={() => navigate('/products')}
                    >
                      Shop Now
                    </button>
                  </div>
                </div>
              </div>
              <img className="w-full h-full" src={slide.img} alt={slide.name} />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BannerSlider;
