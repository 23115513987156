// src/pages/About.js
import React from 'react';
import Banner from '../components/Banner';
import video from '../img/Agrowfarm.mp4';
import image1 from '../img/image1.jpeg';
import Testimonials from '../components/Testimonials';
import WhatWeDo from '../components/WhatWeDo';
import LatestPodcasts from '../components/LatestPodcasts';

function About() {
  return (
    <>
      <Banner />
      <WhatWeDo />
      <Testimonials />
      <LatestPodcasts />
    </>
  );
}

export default About;
